import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { ValidarUsuario } from '../../api/usuario';
import ModalEspera from '../ModalEspera';

import '../../styles/reset.css';

const PasoUno = ({ setPaso, setActivo2, setUsuario, setUsuarioAD }) => {
  const [userID, setUserID] = useState('');
  const [modalVisible, setModalVisible] = useState(false);

  const ObtenerCaptcha = () => {
    setModalVisible(true);

    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute('6Lcsrj8hAAAAAAxh1LJ4KW4uWFO00T8PN6vSrx0d', {
          action: 'submit',
        })
        .then((token) => {
          VerificarUsuario(token);
        })
        .catch((error) => {
          console.error(error);
          setModalVisible(false);
          toast.error(
            'Ha ocurrido un error. Por favor, notifique a la Mesa de Ayuda.'
          );
        });
    });
  };

  async function VerificarUsuario(captchaToken) {
    const value = await ValidarUsuario(userID, 'Reseteo', captchaToken);

    setModalVisible(false);

    if (!value) {
      return;
    }

    if (value.mensaje === 'OK' || value.mensaje === 'OK_SMS') {
      setUsuario(value);
      setUsuarioAD(userID);
      setPaso(2);
      setActivo2(true);

      const mensajeOK =
        value.mensaje === 'OK'
          ? 'Se ha enviado un código de verificación a su celular asociado. En caso no reciba el código, notifique a la Mesa de Ayuda.'
          : 'El usuario tiene un código de validación vigente. En caso de no haber recibido el código, notifique a la Mesa de Ayuda.';

      toast.success(mensajeOK, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } else {
      toast.warn(value.mensaje, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  }

  return (
    <div>
      <div className='card-header m-0 p-0'>
        <h6>
          <b>Solicitud de código de validación</b>
        </h6>
      </div>
      <div className='card-body p-1'>
        <p>
          Ingresa tu cuenta de red en el casillero de abajo. Luego de validar
          tus datos, te enviaremos un mensaje SMS con el código de verificación
          al número móvil registrado en su perfil de cuenta de red. Recuerde que
          la contraseña debe cumplir con la política de seguridad establecida
          por la División Minera.
        </p>
        <div className='position-relative form-group'>
          <label>
            <b>Ingresa tu cuenta de red</b>
          </label>
          <input
            name='txtCuentaRed'
            id='txtCuentaRed'
            placeholder='Cuenta de red'
            type='text'
            className='form-control'
            value={userID}
            onChange={(ev) => setUserID(ev.target.value)}
            onKeyDown={(ev) => ev.key === 'Enter' && ObtenerCaptcha()}
          />
        </div>
      </div>
      <div className='divider'></div>
      <div className='clearfix'>
        <button
          className='btn-shadow btn-wide float-right btn-pill btn-hover-shine btn btn-primary bgCustom'
          // style="background:#005293;"
          onClick={ObtenerCaptcha}
        >
          Solicitar
        </button>
      </div>
      <ModalEspera mensaje='Validando usuario de red' visible={modalVisible} />
    </div>
  );
};

export default PasoUno;
