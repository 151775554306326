import axios from 'axios';
import { toast } from 'react-toastify';

const baseURL = `${process.env.REACT_APP_URL_API}`;

async function ValidarUsuario(usuarioRed, tipo, captchaToken) {
  if (usuarioRed === '' || usuarioRed === null) {
    toast.warn('El usuario no existe o la acción no esta permitida.', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
    return null;
  }

  try {
    const response = await axios.post(baseURL + '/ValidarUsuario', null, {
      params: {
        usuarioRed: usuarioRed,
        tipo: tipo,
        GoogleCaptchaToken: captchaToken,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.mensaje) {
      toast.warn(error.response.data.mensaje, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } else {
      toast.error(
        'Ha ocurrido un error. Por favor, notifique a la Mesa de Ayuda.',
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        }
      );

      return null;
    }
  }
}

async function ValidarVigenciaCodigo(
  codigoSMS,
  usuarioRed,
  tipo,
  nuevaContrasena,
  repiteContrasena
) {
  try {
    const response = await axios.post(
      baseURL + '/ValidarVigenciaCodigo',
      null,
      {
        params: {
          codigoSMS: codigoSMS,
          usuarioRed: usuarioRed,
          tipo: tipo,
          ...(nuevaContrasena ? { nuevaContrasena: nuevaContrasena } : {}),
          ...(repiteContrasena ? { repiteContrasena: repiteContrasena } : {}),
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.mensaje) {
      toast.warn(error.response.data.mensaje, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } else {
      toast.error(
        'Ha ocurrido un error. Por favor, notifique a la Mesa de Ayuda.',
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        }
      );

      return null;
    }
  }
}

export { ValidarUsuario, ValidarVigenciaCodigo };
