import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

const PasoTres = () => {
  const navigate = useNavigate();
  const Finalizar = () => {
    navigate('/');
  };

  return (
    <div>
      <div className='no-results'>
        <div className='swal2-icon swal2-success swal2-animate-success-icon'>
          <div
            className='swal2-success-circular-line-left bgCustom'
            // style="background-color: rgb(255, 255, 255);"
          ></div>
          <span className='swal2-success-line-tip'></span>
          <span className='swal2-success-line-long'></span>
          <div className='swal2-success-ring'></div>
          <div
            className='swal2-success-fix bgCustom'
            // style="background-color: rgb(255, 255, 255);"
          ></div>
          <div
            className='swal2-success-circular-line-right bgCustom'
            // style="background-color: rgb(255, 255, 255);"
          ></div>
        </div>
        <div className='text-center results-title'>
          Tu contraseña ha sido actualizada con éxito!
        </div>
        <br />
        <div className='text-center'>
          <button
            className='btn-shadow btn-wide btn btn-success btn-lg'
            onClick={Finalizar}
          >
            Finalizar
          </button>
        </div>
      </div>
    </div>
  );
};

export default PasoTres;
