import React, { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { ValidarVigenciaCodigo } from '../../api/usuario';
import ModalEspera from '../ModalEspera';

const PasoDos = ({ setPaso, setActivo3, usuario, usuarioAD }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [values, setValues] = useState({
    newPassword: '',
    confirmNewPassword: '',
    codigoValidacion: '',
  });

  const limitarEscritura = (event) => {
    if (event.key === 'Enter') {
      ValidarUsuario();
    }
  };

  const handleInput = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  async function ValidarUsuario() {
    if (values.codigoValidacion === '' || values.codigoValidacion === null) {
      toast.warn('Debe ingresar el código de verificación.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      return;
    } else if (values.newPassword !== values.confirmNewPassword) {
      toast.warn('Las contraseñas deben coincidir.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      return;
    } else {
      setModalVisible(true);

      const value = await ValidarVigenciaCodigo(
        values.codigoValidacion,
        usuarioAD,
        'Reseteo',
        values.newPassword,
        values.confirmNewPassword
      );

      if (!value) {
        return;
      }

      setModalVisible(false);

      if (value.mensaje === 'OK') {
        setActivo3(true);
        setPaso(3);
      } else {
        let mensajeToast = '';

        if (value.mensaje === 'E_USUARIO') {
          mensajeToast =
            'El usuario no existe o la acción no esta permitida, notifique a la Mesa de Ayuda.';
        } else if (value.mensaje === 'B_USUARIO') {
          mensajeToast = 'El usuario no existe o la acción no esta permitida.';
        } else if (value.mensaje === 'E_CNX_LDAP') {
          mensajeToast =
            'El usuario no existe o la acción no esta permitida, notifique a la Mesa de Ayuda.';
        } else if (value.mensaje === 'E_POLITICA') {
          mensajeToast =
            'La nueva contraseña debe de tener al menos 8 caracteres y al menos un caracter en mayúscula, uno en minúscula, un digito y un carácter especial !@#$%*(). No incluye espacios.';
        } else if (value.mensaje === 'E_POLITICA2') {
          mensajeToast =
            'La contraseña especificada no cumple con la política de seguridad establecida por la División Minera.';
        } else if (value.mensaje === 'E_PASSWORD') {
          mensajeToast = 'Las contraseñas deben coincidir.';
        } else {
          mensajeToast = value.mensaje;
        }

        toast.warn(mensajeToast, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    }
  }

  return (
    <div>
      <div className='card-header m-0 p-0'>
        <h6>
          <b>Ingrese el código de validación</b>
        </h6>
      </div>
      <div className='card-body p-1'>
        <p
        // style="text-align:justify;"
        >
          Hola, <b>{usuario.nombre}</b>. Se ha generado un código de
          verificación y enviado en un mensaje SMS al número móvil (
          {usuario.celular}) registrado en su perfil de cuenta de red. El
          mensaje SMS puede tomar algún tiempo dependiendo de varios factores
          como ubicación, fuerza de señal o carga en su operador móvil. Recuerde
          que este código de verificación tiene una vigencia de 15 minutos desde
          que fue solicitado.
        </p>
        <div className='position-relative form-group'>
          <label>
            <b>Ingrese su nueva contraseña</b>
          </label>
          <input
            id='txtNuevaContrasena'
            placeholder='Nueva contraseña'
            type='password'
            className='form-control'
            name='newPassword'
            onChange={handleInput}
            onKeyDown={(ev) => ev.key === 'Enter' && ValidarUsuario()}
          />
          <label>
            <b>Confirme su nueva contraseña</b>
          </label>
          <input
            id='txtRepiteContrasena'
            placeholder='Repita la contraseña'
            type='password'
            className='form-control'
            name='confirmNewPassword'
            onChange={handleInput}
            onKeyDown={(ev) => ev.key === 'Enter' && ValidarUsuario()}
          />
          <label>
            <b>Código de validación</b>
          </label>
          <input
            id='txtCodigoValidacion'
            placeholder='Código de validación'
            type='text'
            className='form-control'
            maxLength='8'
            onKeyDown={limitarEscritura}
            onChange={handleInput}
            name='codigoValidacion'
          />
        </div>
      </div>
      <div className='divider'></div>
      <div className='clearfix'>
        <button
          id='btnSolicitar'
          className='btn-shadow btn-wide float-right btn-pill btn-hover-shine btn btn-primary bgCustom'
          onClick={ValidarUsuario}
        >
          Solicitar
        </button>
      </div>
      <ModalEspera mensaje='Validando usuario de red' visible={modalVisible} />
    </div>
  );
};

export default PasoDos;
